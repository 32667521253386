import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selected: string;
  tempArr: string[];
  dateSelected: string[];
  dateSelectedUpdate: string[];
  markedDates: any;
  selectedDates: '';
  selectedStartDate: any;
  selectedEndDate: any,
  previousTitle: string;
  nextTitle: string;
  markedDates1: any,
  Booking_details: any,
  Upcoming_booking: any,
  Previous_bookings: any,
  End_Dates: any,
  Start_Dates: any,
  End_Datess: any,
  Start_Datess: any,

  showModal: boolean,
  isModalVisible: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReservationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAbout_communitysCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    // Customizable Area Start
    // Customizable Area End


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      previousTitle: '',
      nextTitle: '',
      selected: '',
      showModal: false,
      dateSelected: [],
      Booking_details: [],
      Previous_bookings: [],
      Upcoming_booking: [],
      dateSelectedUpdate: [],
      End_Dates: [],
      Start_Dates: [],
      tempArr: [],
      End_Datess: [],
      Start_Datess: [],
      markedDates: '',
      selectedDates: '',
      selectedStartDate: '',
      selectedEndDate: '',
      markedDates1: '',
      isModalVisible: false
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      if (responseJson && !responseJson.errors) {
        this.setState({ Previous_bookings: responseJson }, () => {
          const endDates = this.state.Previous_bookings?.previous_bookings?.end_date;
          const startDates = this.state.Previous_bookings?.previous_bookings?.start_date;
          const formattedEndDates = endDates && moment(endDates, 'DD-MM-YYYY').format('DD MMM');
          const formatteStartDates = startDates && moment(startDates, 'DD-MM-YYYY').format('DD MMM');
          this.setState({ Start_Dates: formatteStartDates, End_Dates: formattedEndDates })
        })
      }
      if (responseJson && !responseJson.errors) {
        this.setState({ Upcoming_booking: responseJson }, () => {
          const endDates = this.state.Upcoming_booking?.upcoming_bookings?.end_date;
          const startDates = this.state.Upcoming_booking?.upcoming_bookings?.start_date;
          const formattedEndDatess = endDates && moment(endDates, 'DD-MM-YYYY').format('DD MMM');
          const formatteStartDatess = startDates && moment(startDates, 'DD-MM-YYYY').format('DD MMM');
          this.setState({ Start_Datess: formatteStartDatess, End_Datess: formattedEndDatess })
        })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getPreviewBooking()
  }
  handleConfirmBooking = () => {
    this.handleBookingDetails();
  };
  handleBookingDetails() {
    this.props.navigation.navigate("BookingDetails")
  }
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    let token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzQsImV4cCI6MTY5MDkwNTk4NywidG9rZW5fdHlwZSI6ImxvZ2luIn0.SkX4oe-h_byIduIBB3jyG6MrakItDz6Vi7VTfcoEzeZbSiTsAYDs52lDuUpwJWxFpIS2FVXS4gxJSYSt9nu1Qw"
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type != "formData" ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getPreviewBooking = async () => {
    let httpbody = {
    }
    this.apiAbout_communitysCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: 'bx_block_booking/my_bookings/latest_bookings',
      body: httpbody
    });

  };

  // Customizable Area End
}
