Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.baseUrl = "https://huberusha1-293260-ruby.b293260.dev.eastus.az.svc.builder.cafe";
exports.title1="testpasssword";
exports.title2="Sell / Buy products";
exports.title3=" Buy products";
exports.title4="Sell products";

exports.subtitle1="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle2="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native";
exports.subtitle3="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle4="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";


exports.text1 = "Please enter credentials provided by your admin. if you don’t have credentials please contact admin."
exports.text2 = "Don’t worry it happens. Please enter the email address associated with your account."
exports.text3 = "We have successfully sent an OTP to your email address. If not received try again after 10 minutes"
exports.text4 = "Please enter one time password sent to your email address. if you haven’t received it please contact admin."
exports.text5 = "Please enter password that you have set firsttime.if its your first time login go back to setup account"

// Customizable Area End