import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
//@ts-ignore
import timer from "react-native-timer";
import {
  setStorageData,
  getStorageData,
} from "../../../framework/src/Utilities";
import { BackHandler, Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  screenProps: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  onboardstart: { id: number; title: string; description: string };
  isPassword: boolean;
  isExisinguser: boolean;
  isPersonalpreview: boolean;
  showEmailsentpopup: boolean;
  Employeetag: string;
  shownewpassword: boolean;
  isOTPinput: boolean;
  loginData: {
    usertokendata: any;
    loading: boolean;
    errortext: string | undefined;
  };
  firstLoginData: {
    usertokendata: any;
    loading: boolean;
    errortext: string | undefined;
  };
  username: string;
  usercontact: string;
  birthdate: string;
  useraddress: {
    street: string;
    apartment: string;
    city: string;
    country: string;
    state: string;
    postalcode: string;
    privatecheck: boolean;
  };
  profilephoto: {
    uri: string;
    name: string;
    type: string;
    photoupdate: { loading: boolean; errortext: string | undefined };
  };
  designationlist: {
    listdata: Array<{}>;
    loading: boolean;
    errortext?: string;
  };
  departmentlist: {
    listdata: Array<{}>;
    loading: boolean;
    errortext?: string;
  };
  reportinlist: {
    listdata: Array<{}>;
    loading: boolean;
    errortext?: string;
  };

  createempdata: {
    responsedata: { attributes: { full_name: string } } | undefined;
    loading: boolean;
    errortext: any;
  };

  profileupdatedata: {
    responsedata: object | undefined;
    loading: boolean;
    errortext: any;
  };
  usercompanydata: {
    responsedata: { corporate_admin: boolean } | undefined;
    loading: boolean;
    errortext: string | undefined;
  };
  forgetpassdata: {
    responsedata: { token?: string } | undefined;
    loading: boolean;
    errortext: string | undefined;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingguideController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      onboardstart: {
        id: 0,
        title: "Get more out of your workplace ",
        description:
          "Book and collaborate with colleagues , book transport , meals and much more.",
      },
      isPassword: false,
      isExisinguser: false,
      isPersonalpreview: true,
      showEmailsentpopup: false,
      Employeetag: "teamlead",
      shownewpassword: true,
      isOTPinput: false,
      loginData: {
        usertokendata: undefined,
        loading: false,
        errortext: undefined,
      },
      firstLoginData: {
        usertokendata: undefined,
        loading: false,
        errortext: undefined,
      },
      username: "",
      usercontact: "",
      birthdate: "",
      useraddress: {
        street: "",
        apartment: "",
        city: "",
        country: "",
        state: "",
        postalcode: "",
        privatecheck: false,
      },
      profilephoto: {
        uri: "",
        type: "",
        name: "",
        photoupdate: { loading: false, errortext: undefined },
      },
      designationlist: { listdata: [], loading: false, errortext: undefined },
      departmentlist: { listdata: [], loading: false, errortext: undefined },
      reportinlist: { listdata: [], loading: false, errortext: undefined },
      createempdata: {
        responsedata: { attributes: { full_name: "New user" } },
        loading: false,
        errortext: undefined,
      },
      profileupdatedata: {
        responsedata: undefined,
        loading: false,
        errortext: undefined,
      },
      usercompanydata: {
        responsedata: { corporate_admin: false },
        loading: false,
        errortext: undefined,
      },
      forgetpassdata: {
        responsedata: undefined,
        loading: false,
        errortext: undefined,
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.postloginid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.doLoginUserResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.doLoginUserReject(errorReponse, "Please Try Again!");
      } else if (this.getdesignationid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.DropdownResolve(responseJson, "designationlist");

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.DropdownReject(errorReponse, "Network Error", "designationlist");
      } else if (this.getdepartmentid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.DropdownResolve(responseJson, "departmentlist");

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.DropdownReject(errorReponse, "Network Error", "departmentlist");
      } else if (this.getreportinid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.DropdownResolve(responseJson, "reportinlist");

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.DropdownReject(errorReponse, "Network Error", "reportinlist");
      } else if (this.postcreateempid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.postCreateEmpResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.postCreateEmpReject(errorReponse, "Network Error");
      } else if (this.patchprofieid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.patchUserProfileResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.patchUserProfileReject(errorReponse, "Network Error");
      } else if (this.getUserdetailsid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.getUserDetailsResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.getUserDetailsReject(errorReponse, "Network Error");
      } else if (this.postprofilephotoid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.postProfilephotoResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.postProfilephotoReject(errorReponse);
      } else if (this.postotploginid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.postOtpLoginResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.postOtpLoginReject(errorReponse);
      } else if (this.patchuserpasswordid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.patchUserPassResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.patchUserPassReject(errorReponse);
      } else if (this.postforgetpassotpid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.postForgetPassOtpResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.postForgetOtpReject(errorReponse);
      } else if (this.postconfirmforgetotpid === apiRequestCallId) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.postConfirmForgetOtpResolve(responseJson);

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.postForgetOtpReject(errorReponse);
      }
    }
  }

  // Customizabele Area Start
  parentNavigation = () => {
    return this.props.screenProps();
  };

  backAction = () => {
    Alert.alert("Wait!", "Are you sure you want to exit?", [
      {
        text: "Cancel",
        onPress: () => null,
        style: "cancel",
      },
      { text: "YES", onPress: () => BackHandler.exitApp() },
    ]);
    return true;
  };

  getStartedData = [
    {
      id: 0,
      title: "Get more out of your workplace ",
      description:
        "Book and collaborate with colleagues , book transport , meals and much more.",
    },
    {
      id: 1,
      title: "Enjoy more out of your workplace ",
      description:
        "Share your ideas with colleagues and dont forget to shoutout for them.",
    },
    {
      id: 2,
      title: "Meet more peoples",
      description: "Share your ideas with others to get to know them ",
    },
  ];

  getAsyncstoreToken = async () => {
    return await getStorageData("authToken");
  };

  handleonboardnavigation = async () => {
    let localtoken = await this.getAsyncstoreToken();
    if (localtoken && localtoken.length > 2) {
      this.parentNavigation();
      timer.clearTimeout("splashnav");
    } else {
      this.props.navigation.navigate("getstarted");
      timer.clearTimeout("splashnav");
    }
  };

  timedindex = 0;
  handleonboardstartswipe = () => {
    this.setState({ onboardstart: this.getStartedData[this.timedindex] });
    this.timedindex = this.timedindex + 1;
    if (this.timedindex >= 3) {
      this.timedindex = 0;
    }
  };

  handlestartupnavigation = () => {
    timer.clearInterval("getstartednav");
    this.props.navigation.navigate("startup");
  };

  handleloginnavigator = (isExisting: boolean) => {
    if (isExisting) {
      this.setState({ isPassword: true, isExisinguser: true }, () => {
        this.props.navigation.navigate("loginpage", { newstate: this.state });
      });
    } else {
      this.setState({ isPassword: false, isExisinguser: false }, () => {
        this.props.navigation.navigate("loginpage", { newstate: this.state });
      });
    }
  };

  handlecreatepasswordnavigation = () => {
    if (this.state.isExisinguser === true && this.state.isPassword) {
      this.parentNavigation();
    } else if (!this.state.isExisinguser && this.state.isPassword) {
      this.props.navigation.navigate("namepage", { newstate: this.state });
    } else {
      this.props.navigation.navigate("createpass", { newstate: this.state });
    }
  };

  handlemobnumnavigation = () => {
    this.props.navigation.navigate("mobnumpage", { newstate: this.state });
  };

  handlebirthdatenavigation = (values: any) => {
    this.setState({ usercontact: values.phonenumber });
    this.props.navigation.navigate("birthdatepage", { newstate: this.state });
  };

  handleraddressnavigation = (values: any) => {
    this.setState({ birthdate: values.birthdate });
    this.props.navigation.navigate("addresspage", { newstate: this.state });
  };

  handlerprofilephotonavigation = (values: S["useraddress"]) => {
    this.setState({ useraddress: values });
    this.props.navigation.navigate("profilephotopage", {
      newstate: this.state,
    });
  };

  handlerpreviewnavigation = () => {
    this.props.navigation.navigate("previewpage", { newstate: this.state });
  };

  handleadminstartnavigation = () => {
    if (this.state.usercompanydata.responsedata!.corporate_admin) {
      this.props.navigation.navigate("adminstart", { newstate: this.state });
    } else {
      this.parentNavigation();
    }
  };

  handleadminformnavigation = () => {
    this.props.navigation.navigate("adminform");
  };

  handleforgetpassnavigation = () => {
    this.props.navigation.navigate("forgetpass");
  };

  togglePersonaltab = () => this.setState({ isPersonalpreview: true });
  toggleCompanytab = () => this.setState({ isPersonalpreview: false });

  toggleTagname = (tagname: string) => this.setState({ Employeetag: tagname });

  showEmailsentPopup = () => this.setState({ showEmailsentpopup: true });
  hideEmailsentPopup = () => this.setState({ showEmailsentpopup: false });

  getLogintoken = async () => {
    let localtoken = await this.getAsyncstoreToken();
    if (localtoken && localtoken.length > 1) {
      return localtoken;
    } else {
      this.handleloginnavigator(true);
      return null;
    }
  };
  //Login Api call
  postloginid = "";
  doLoginUser = async ({
    useremail,
    password,
  }: {
    useremail: string;
    password?: string;
  }) => {
    this.setState({
      loginData: { ...this.state.loginData, loading: true },
    });

    const loginEndpoint = "/bx_block_login/logins";
    const setLoginuserId = (messageId: string) => {
      this.postloginid = messageId;
    };
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const httpdata = {
      data: {
        attributes: {
          email: useremail,
          password: password,
        },
        type: "email_account",
      },
    };
    await this.makeApiCall({
      header: header,
      apimethod: "POST",
      endpoint: loginEndpoint,
      setMessageId: setLoginuserId,
      httpbody: httpdata,
    });
  };

  doLoginUserResolve = async (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      await setStorageData("authToken", responseJson.meta.token);
      this.setState({
        shownewpassword: false,
        loginData: {
          usertokendata: responseJson.meta,
          loading: false,
          errortext: undefined,
        },
      });
      return this.handlecreatepasswordnavigation();
    } else {
      this.doLoginUserReject({ error: true }, "Invalid User Credentials!");
    }
  };

  doLoginUserReject = (errorResponse: object, errortext: string) => {
    if (errorResponse) {
      this.setState({
        loginData: {
          usertokendata: undefined,
          loading: false,
          errortext: errortext,
        },
      });
    }
  };

  //Verify first time login
  postotploginid = "";
  postOtpLogin = async ({
    useremail,
    otpvalue,
  }: {
    useremail: string;
    otpvalue: string;
  }) => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const httpdata = {
      data: {
        email: useremail,
        otp: otpvalue,
      },
    };

    const setDataid = (messageId: string) => {
      this.postotploginid = messageId;
    };

    let endpoint = "/account_block/verify_first_time_login";

    this.setState({
      firstLoginData: {
        usertokendata: undefined,
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "POST",
      endpoint: endpoint,
      setMessageId: setDataid,
      httpbody: httpdata,
    });
  };

  postOtpLoginResolve = (responseJson: any) => {
    if (responseJson && responseJson.token) {
      this.setState({
        firstLoginData: {
          usertokendata: responseJson,
          loading: false,
          errortext: undefined,
        },
      });
      this.handlecreatepasswordnavigation();
    } else {
      this.setState({
        firstLoginData: {
          usertokendata: undefined,
          loading: false,
          errortext: "Invalid Credentials",
        },
      });
    }
  };

  postOtpLoginReject = (errorResponse: any) => {
    if (errorResponse) {
      this.setState({
        firstLoginData: {
          usertokendata: undefined,
          loading: false,
          errortext: "Something went wrong!",
        },
      });
    }
  };

  //Update User Password
  patchuserpasswordid = "";
  patchUserPass = async ({
    password,
    repassword,
  }: {
    password: string;
    repassword: string;
  }) => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const httpdata = {
      data: {
        password: password,
        password_confirmation: repassword,
      },
    };

    const setDataid = (messageId: string) => {
      this.patchuserpasswordid = messageId;
    };

    let endpoint = `/account_block/password_update?token=${this.state.firstLoginData.usertokendata.token}`;

    await this.makeApiCall({
      header: header,
      apimethod: "PATCH",
      endpoint: endpoint,
      setMessageId: setDataid,
      httpbody: httpdata,
    });
  };

  patchUserPassResolve = (responseJson: any) => {
    if (responseJson) {
      this.showAlert("Success", "Password Updated Successfully!");
      if (this.state.forgetpassdata.responsedata) {
        this.setState({
          loginData: {
            loading: false,
            usertokendata: undefined,
            errortext: "Login with new Password to continue!",
          },
        });
        this.handleloginnavigator(true);
      } else {
        this.setState(
          {
            isPassword: true,
            isExisinguser: false,
            loginData: {
              loading: false,
              usertokendata: undefined,
              errortext: "Login with new Password to continue!",
            },
          },
          () => {
            this.props.navigation.navigate("loginpage", {
              newstate: this.state,
            });
          }
        );
      }
    }
  };

  patchUserPassReject = (errorResponse: any) => {
    if (errorResponse) {
      this.showAlert(
        "Error",
        "Unexpected error occured ! Please try again later"
      );
    }
  };

  //Get Department list
  getdesignationid = "";
  getDesignationList = async () => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: await this.getLogintoken(),
    };

    let endpoint = "/account_block/designation";

    const setDataid = (messageId: string) => {
      this.getdesignationid = messageId;
    };

    this.setState({
      departmentlist: {
        ...this.state.departmentlist,
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "GET",
      endpoint: endpoint,
      setMessageId: setDataid,
    });
  };

  getdepartmentid = "";
  getDepartmentList = async () => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: await this.getLogintoken(),
    };

    let endpoint = "/account_block/department";

    const setDataid = (messageId: string) => {
      this.getdepartmentid = messageId;
    };

    this.setState({
      designationlist: {
        ...this.state.designationlist,
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "GET",
      endpoint: endpoint,
      setMessageId: setDataid,
    });
  };

  getreportinid = "";
  getReportinList = async () => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: await this.getLogintoken(),
    };

    let endpoint = "/account_block/reporting_manager_list";

    const setDataid = (messageId: string) => {
      this.getreportinid = messageId;
    };

    this.setState({
      reportinlist: {
        ...this.state.reportinlist,
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "GET",
      endpoint: endpoint,
      setMessageId: setDataid,
    });
  };

  DropdownResolve = (responseJson: any, datastate: keyof S) => {
    if (responseJson && responseJson.data) {
      this.setState({
        ...this.state,
        [datastate]: {
          listdata: responseJson.data,
          loading: false,
          errortext: undefined,
        },
      });
      return true;
    } else {
      let notfounddata = [{ id: "0", name: "Not found" }];
      this.setState({
        ...this.state,
        [datastate]: {
          listdata: notfounddata,
          errortext: "Unable to fetch list!",
          loading: false,
        },
      });
      return false;
    }
  };

  DropdownReject = (
    errorReponse: object,
    errorText: string,
    datastate: keyof S
  ) => {
    if (errorReponse) {
      let notfounddata = [{ id: "0", name: "Not found" }];
      this.setState({
        ...this.state,
        [datastate]: {
          listdata: notfounddata,
          loading: false,
          errortext: errorText,
        },
      });
      return false;
    }
  };

  //Add employee api
  postcreateempid = "";
  postCreateEmp = async (formdata: any) => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: await this.getLogintoken(),
    };

    let endpoint = "/account_block/add_employee";

    const setDataid = (messageId: string) => {
      this.postcreateempid = messageId;
    };

    const httpdata = {
      full_name: formdata.empname,
      email: formdata.empemail,
      password: configJSON.title1,
      date_of_joining: formdata.empdoj,
      reporting_to: formdata.empreportingto,
      designation_id: formdata.empdesignation,
      department_id: formdata.empdepartment,
      tags: formdata.emptag,
    };

    this.setState({
      createempdata: {
        responsedata: { attributes: { full_name: "New user" } },
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "POST",
      endpoint: endpoint,
      setMessageId: setDataid,
      httpbody: httpdata,
    });
  };

  postCreateEmpResolve = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        showEmailsentpopup: true,
        createempdata: {
          responsedata: responseJson.data.data,
          loading: false,
          errortext: undefined,
        },
      });
      return true;
    } else {
      this.setState({
        showEmailsentpopup: false,
        createempdata: {
          responsedata: { attributes: { full_name: "New user" } },
          loading: false,
          errortext: Object.values(responseJson.error[0]),
        },
      });
    }
  };

  postCreateEmpReject = (errorReponse: object, errortext: string) => {
    if (errorReponse) {
      this.setState({
        showEmailsentpopup: false,
        createempdata: {
          responsedata: { attributes: { full_name: "New user" } },
          loading: false,
          errortext: errortext,
        },
      });
    }
  };

  //Profile update api
  patchprofieid = "";
  patchUserProfile = async () => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: await this.getLogintoken(),
    };

    const httpdata = {
      full_name: this.state.username,
      full_phone_number: this.state.usercontact,
      date_of_birth: this.state.birthdate,
      profiles: {
        country: this.state.useraddress.country,
        postal_code: this.state.useraddress.postalcode,
        profile_role: "recruiter",
        city: this.state.useraddress.city,
        street: this.state.useraddress.street,
        apartment: this.state.useraddress.apartment,
        state: this.state.useraddress.state,
        keep_private: this.state.useraddress.privatecheck,
      },
    };

    const setDataid = (messageId: string) => {
      this.patchprofieid = messageId;
    };

    let endpoint = "/account_block/update";

    this.setState({
      profileupdatedata: {
        responsedata: undefined,
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "PATCH",
      endpoint: endpoint,
      setMessageId: setDataid,
      httpbody: httpdata,
    });
  };

  patchUserProfileResolve = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        profileupdatedata: {
          responsedata: responseJson.data.data,
          loading: false,
          errortext: undefined,
        },
      });

      return this.handleadminstartnavigation();
    } else {
      this.setState({
        profileupdatedata: {
          responsedata: { attributes: { full_name: "New user" } },
          loading: false,
          errortext: Object.values(responseJson.error[0]),
        },
      });
    }
  };

  patchUserProfileReject = (errorReponse: object, errortext: string) => {
    if (errorReponse) {
      this.setState({
        profileupdatedata: {
          responsedata: undefined,
          loading: false,
          errortext: errortext,
        },
      });
    }
  };

  //getUser details
  getUserdetailsid = "";
  getUserDetails = async () => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: await this.getLogintoken(),
    };

    let endpoint = "/account_block/user_company_details";

    const setDataid = (messageId: string) => {
      this.getUserdetailsid = messageId;
    };

    this.setState({
      usercompanydata: {
        responsedata: undefined,
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "POST",
      endpoint: endpoint,
      setMessageId: setDataid,
    });
  };

  getUserDetailsResolve = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        usercompanydata: {
          responsedata: responseJson.data,
          loading: false,
          errortext: undefined,
        },
      });
    } else {
      this.setState({
        usercompanydata: {
          responsedata: { corporate_admin: false },
          loading: false,
          errortext: "Company details not found",
        },
      });
    }
  };

  getUserDetailsReject = (errorReponse: object, errortext: string) => {
    if (errorReponse) {
      this.setState({
        usercompanydata: {
          responsedata: undefined,
          loading: false,
          errortext: errortext,
        },
      });
    }
  };

  //Profile photo update
  postprofilephotoid = "";
  postProfilephoto = async () => {
    let header = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      token: await this.getLogintoken(),
    };
    let endpoint = "/account_block/profile_photo_update";

    const setDataid = (messageId: string) => {
      this.postprofilephotoid = messageId;
    };

    let formdata = new FormData();
    formdata.append("photo", {
      //@ts-ignore
      uri: this.state.profilephoto.uri,
      name: this.state.profilephoto.name,
      type: this.state.profilephoto.type,
    });

    this.setState({
      profilephoto: {
        ...this.state.profilephoto,
        photoupdate: {
          loading: true,
          errortext: undefined,
        },
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "POST",
      endpoint: endpoint,
      setMessageId: setDataid,
      httpbody: formdata,
    });
  };

  postProfilephotoResolve = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        profilephoto: {
          ...this.state.profilephoto,
          photoupdate: {
            loading: false,
            errortext: undefined,
          },
        },
      });
      return this.handlerpreviewnavigation();
    } else {
      this.setState({
        profilephoto: {
          ...this.state.profilephoto,
          photoupdate: {
            loading: false,
            errortext: "Failed to upload photo!",
          },
        },
      });
    }
  };

  postProfilephotoReject = (errorjson: any) => {
    if (errorjson) {
      this.setState({
        profilephoto: {
          ...this.state.profilephoto,
          photoupdate: {
            loading: false,
            errortext: "Unable to upload photo!",
          },
        },
      });
    }
  };

  //Forget password 1st Api - Sending email from otp
  postforgetpassotpid = "";
  postForgetPassOtp = async ({ useremail }: { useremail: string }) => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let endpoint = "/bx_block_forgot_password/otps";

    const setDataid = (messageId: string) => {
      this.postforgetpassotpid = messageId;
    };

    const httpdata = {
      data: {
        attributes: {
          email: useremail,
        },
        type: "email_account",
      },
    };

    this.setState({
      forgetpassdata: {
        responsedata: undefined,
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "POST",
      endpoint: endpoint,
      setMessageId: setDataid,
      httpbody: httpdata,
    });
  };

  postForgetPassOtpResolve = (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      this.setState({
        showEmailsentpopup: true,
        forgetpassdata: {
          responsedata: responseJson.meta,
          loading: false,
          errortext: undefined,
        },
      });
    } else {
      this.setState({
        forgetpassdata: {
          responsedata: undefined,
          loading: false,
          errortext: "Account not found",
        },
      });
    }
  };

  postForgetOtpReject = (errorResponse: any) => {
    if (errorResponse) {
      this.setState({
        forgetpassdata: {
          responsedata: undefined,
          loading: false,
          errortext: "Something went wrong!",
        },
      });
    }
  };

  //Forget password 1st Api - Confirming Email otp
  postconfirmforgetotpid = "";
  postConfirmForgetOtp = async ({ otpvalue }: { otpvalue: string }) => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let endpoint = "/bx_block_forgot_password/otp_confirmations";

    const setDataid = (messageId: string) => {
      this.postconfirmforgetotpid = messageId;
    };

    const httpdata = {
      data: {
        token: this.state.forgetpassdata.responsedata!.token,
        otp_code: otpvalue,
      },
    };

    this.setState({
      forgetpassdata: {
        ...this.state.forgetpassdata,
        loading: true,
        errortext: undefined,
      },
    });

    await this.makeApiCall({
      header: header,
      apimethod: "POST",
      endpoint: endpoint,
      setMessageId: setDataid,
      httpbody: httpdata,
    });
  };

  postConfirmForgetOtpResolve = (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      this.setState({
        firstLoginData: {
          usertokendata: responseJson.meta,
          loading: false,
          errortext: undefined,
        },
        forgetpassdata: {
          ...this.state.forgetpassdata,
          loading: false,
          errortext: undefined,
        },
      });
      return this.props.navigation.navigate("createpass", {
        newstate: this.state,
      });
    } else {
      this.setState({
        forgetpassdata: {
          responsedata: undefined,
          loading: false,
          errortext: "Otp is invalid",
        },
      });
    }
  };

  //Common Api call
  makeApiCall = async ({
    header,
    apimethod,
    httpbody,
    endpoint,
    setMessageId,
  }: {
    header: Object;
    httpbody?: Object;
    apimethod: string;
    endpoint: string;
    setMessageId: Function;
  }) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const messageId = requestMessage.messageId;
    setMessageId(messageId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apimethod
    );
    if (httpbody) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpbody)
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
