Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.labelTitleText = "Reervations";
exports.labelBodyText = "Find you favourite";
exports.labelBodySubText = "place to work";
exports.labelheaderBody = "Upcoming Bookings";
exports.labelheaderBodys = "Previous Bookings";
exports.labelheaderBodyCalendar = 'Booking Details';
exports.labelheaderBodyCalendar1 = 'Details';
exports.labelheaderBodyCalendar2 = 'Booking';
exports.labelheaderMarketPlace = 'My Bookings';
exports.labelSubText = 'Access Everything';
exports.labelSubTexts = 'Do Anything !';
exports.labelMarketPlaceText = 'It is long establised fact that a reader will be distracted by the readable.';
exports.labelheaderBodyCalendarDetails = 'Booking Details';
exports.labelDay1 = "Day";
exports.labelDateBody = 'Date';
exports.labelDateBody1 = '23 Mar 2023';
exports.labelDateBody2 = 'To';
exports.labelDateBody3 = '25 Mar 2023';
exports.labelScheduled1 = 'Scheduled on';
exports.labelHuber1 = 'Huber';
exports.labelTime1 = 'Time';
exports.labelSeating1 =  'Seating';
exports.labelScheduledDay1 = 'Wednesday, 18 Jan, 2023';
exports.labelCorporate1 = 'Corporate hub';
exports.labelCorporateTime1 = '12:00 - 04:00 PM';
exports.labelMeetingRoom1 = 'Meeting room 1';
exports.labelHeadingsDay1 = 'Day 1';
exports.labelHeadingsDate1 = '18 April';
exports.labelHeadingsDay2 = 'Day 2';
exports.labelHeadingsDate2 = '19 April';
exports.labelHeadingsDay3 = 'Day 3';
exports.labelHeadingsDate3 = '20 April';
exports.labelConfirmBookingText = 'Confirming booking';
exports.labelLoadingText = 'Loading';
exports.btnExampleTitle = "CLICK ME";
exports.labelBodyHead = "Corporate Hub";
exports.labelBodyHead1 = "Huber";
// Customizable Area End