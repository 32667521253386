export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgNavbar = require("../assets/navbar.png");
export const imgCalendarMarkeplace = require("../assets/calendarMarkeplace.png");
export const imgCards1 = require("../assets/cards1.png");
export const imgCards2 = require("../assets/OfficeCards.png");
export const imgCards3 = require("../assets/cards3.png");
export const imgCards4 = require("../assets/cards4.png");
export const imgBuilding3 = require("../assets/building-3.png");
export const imgBuilding = require("../assets/buliding.png");
export const imgLine = require("../assets/Line.png");
export const imgLines = require("../assets/viewLine.png");
export const imgSearch = require("../assets/search-normal.png");
export const imgGroup = require("../assets/group1.png");
export const imgPassword = require("../assets/icons.png");
export const imgCheckList = require("../assets/ImgCheckLists.png");
export const imgCheck = require("../assets/checkkImg.png");

